import { initialAbility } from "@/libs/acl/config"
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  data() {
    return {
      signInEmail: null,
      password: null,
      loginInProgress: false,
      responseData: null,
    }
  },
  beforeMount() {
    this.$bus.$on('account-selected', this.loginCompleted)
  },
  beforeDestroy() {
    this.$bus.$off('account-selected', this.loginCompleted)
  },
  methods: {
    initComponentData() {
      this.signInEmail = null,
      this.password = null,
      this.loginInProgress = false,
      this.responseData = null
    },
    submitLoginData() {
      this.$store
        .dispatch("user/login", {
          email: this.signInEmail,
          password: this.password,
        })
        .then((response) => {
          this.loginInProgress = true
          this.responseData = response
          this.$i18n.locale = this.responseData.userData.locale
          if(this.responseData.userRoles.length > 1) {
            if(this.responseData.userRoles.includes('mentor')) {
              this.$store.dispatch('user/setHasMentorAccount', true)
            }
            if(this.responseData.userRoles.includes('mentee')) {
              this.$store.dispatch('user/setHasMenteeAccount', true)
            }
            if(this.responseData.userRoles.includes('admin')) {
              this.$store.dispatch('user/setHasAdminAccount', true)
            }
            this.$bus.$emit('account-selection-required', this.responseData.userRoles)
          }
          else {
            this.loginCompleted(this.responseData.userRoles[0])
          }
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.$t('Your username or password is invalid'), {
            title: this.$t('Caution'),
            variant: 'danger',
            solid: true,
          })
        });
    },
    loginCompleted(accountType) {
      if (this.loginInProgress && this.responseData) {
        this.$store.dispatch('user/saveUserRole', accountType)
        this.$store.dispatch('user/saveUserAbility', accountType)
        this.$store.dispatch('user/saveUserData', this.responseData.userData)
        // localStorage.setItem('userData', JSON.stringify(this.responseData.userData))
        
        // this.initComponentData()

        const route = getHomeRouteForLoggedInUser(this.$store.getters['user/getUserRole'])
        if (this.$route.name !== route.name) {
          this.$router.push(route)
        }
      }
    }
  },
}