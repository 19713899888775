<template>
  <b-modal
    id="choose-account-modal"
    ref="choose-account-modal"
    centered
    ok-only
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    :title="$t('ChooseProfile')"
    title-class="font-weight-bolder"
    :ok-title="$t('Choice')"
    @ok="submitData"
    :ok-disabled="!canProceed"
  >
    <b-row>
      <b-col>
        <b-form-group>
          <template #label>
            ☝️ {{ $t('You can always switch profile from your account!') }}
          </template>
          <b-form-radio-group
            id="select-account-type"
            v-model="selectedAccountType"
            :options="accountOptions"
            name="radio-options"
          >
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BModal,
  BRow,
} from "bootstrap-vue";

export default {
  components: {
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BModal,
    BRow
  },
  data() {
    return {
      selectedAccountType: null,
      accountOptions: []
    }
  },
  computed: {
    canProceed() {
      return this.accountOptions.filter(option => option.value === this.selectedAccountType).length === 1
    }
  },
  beforeMount() {
    this.$bus.$on('account-selection-required', this.showChooseAccountModal)
  },
  beforeDestroy() {
    this.$bus.$off('account-selection-required', this.showChooseAccountModal)
  },
  methods: {
    showChooseAccountModal(userRoles) {
      if(userRoles.includes('mentor')) {
        this.accountOptions.push({ text: 'Mentor', value: 'mentor' })
      }
      if(userRoles.includes('mentee')) {
        this.accountOptions.push({ text: 'Mentee', value: 'mentee' })
      }
      if(userRoles.includes('admin')) {
        this.accountOptions.push({ text: 'Admin', value: 'admin' })
      }
      this.$refs['choose-account-modal'].show()
    },
    async submitData() {
      const accountSelected = await this.$store.dispatch('user/chooseAccount', this.selectedAccountType)
      if(!accountSelected) {
        this.selectedAccountType = null
        await this.$store.dispatch('user/logout')
        this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
          title: this.$t('Caution'),
          variant: 'danger',
          solid: true,
        })
        return
      }
      this.$bus.$emit('account-selected', this.selectedAccountType)
    }
  }
};
</script>

<style>
</style>
